import * as React from 'react';
import PageTemplate from '../components/page-template';

const Work = () => {
  return (
    <PageTemplate className="my-5">
      <h1>Work With Me</h1>
      <p>This page is currently under development. Please come back later.</p>
    </PageTemplate>
  )
};

export default Work;
